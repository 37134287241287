import '../styles.css'
import {Chip, Grid, Icon} from "@mui/material";
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIcon from '@mui/icons-material/Phone';
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';

function Footer(){
    return (
        <div className={'footer'}>
            <Grid container sm={12} display={'row'} justifyContent={'center'} alignItems={'flex-start'} width={'100vw'} spacing={2}>
                <Grid item sm={3}>

                    <h2>SERVICE AUTO AUTORIZAT</h2>
                    <p> Ne mândrim cu servicii de înaltă calitate, oferind reparații și întreținere pentru o gamă variată de mașini.</p>
                </Grid>
                <Grid item sm={3}>
                    <h2>REPARAȚII INJECTOARE & CURĂȚARE DPF</h2>
                    <p> Reparăm injectoare și curățăm filtre de particule la prețuri corecte, în funcție de problemele acestora.</p>
                    <p> Puteți consulta lista de prețuri &nbsp;<a href={''}> aici </a>
                    </p>

                </Grid>

                <Grid item sm={3}>
                    <h2>CONTACTEAZĂ-NE</h2>

                    <Box width="100%"/>

                    <Chip icon={<PhoneIcon style={{color: 'red'}}/>} label={'0769 077 600'}
                              style={{
                                  color: 'whitesmoke',
                                  backgroundColor: '#676767',
                                  paddingLeft: '4vw',
                                  fontSize: '16px',

                              }}
                              onClick={()=>{
                                  window.open('tel:+40769077600')
                              }}
                        />



                        <Chip icon={<PlaceIcon style={{color: 'red'}}/>} label={'Târgu Jiu, Gorj, Calea Tismanei 26'}
                              style={{
                                  color: 'whitesmoke',
                                  backgroundColor: '#676767',
                                  paddingLeft: '4vw',
                                  fontSize: '16px',
                              }}

                        />

                </Grid>
                <Grid item sm={3}>
                    <h2>URMĂREȘTE-NE ȘI PE SOCIAL MEDIA</h2>
                    <div style={{paddingLeft: '5vw'}}>
                        <IconButton onClick={() => {window.open('https://www.facebook.com/Injectoare.gj')}}>
                            <FacebookIcon/>
                        </IconButton>
                         <IconButton onClick={() => {window.open('https://www.facebook.com/Injectoare.gj')}}>
                             <InstagramIcon/>
                        </IconButton>

                        <IconButton onClick={() => {window.open('https://www.facebook.com/Injectoare.gj')}}>
                             <YouTubeIcon/>
                        </IconButton>


                    </div>

                </Grid>
            </Grid>
        </div>
    );
}

export default Footer