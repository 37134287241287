import React from 'react';
import '../../styles.css'
import {Grid, Card} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

function AboutSection ({imageLink, title, paragraphs, mirror, last=false}) {
    const desktop = useMediaQuery('(min-width:1200px)');

    return (
            desktop ?
                <div>
                    {
                        mirror
                            ?
                            <div className={'centered-items'}>
                                <Card elevation={12} alignItems={'center'} sx={{ padding: 10, width: '80vw'}}>
                                    <Grid container sm={14} display={'row'} alignItems={'center'} justifyContent={'center'} spacing={2}>
                                        <Grid item xs={6}>
                                            <div className={'about-section-image'}>
                                                <img src={imageLink}  alt=""/>
                                            </div>

                                        </Grid>


                                        <Grid item xs={6}>
                                            <div className={'styled-text'}>
                                                <h2>{title}</h2>
                                                {paragraphs.map((text) => {
                                                    return (<p> {text} </p>);
                                                })}
                                            </div>

                                        </Grid>


                                    </Grid>

                                </Card>
                            </div>
                            :
                            <div className={'centered-items'}>
                                <Card elevation={12} alignItems={'center'} sx={{ padding: 10, width: '80vw'}}>
                                    <Grid container sm={14} display={'row'} alignItems={'center'} justifyContent={'center'} spacing={2}>
                                        <Grid item xs={6}>
                                            <div className={'styled-text'}>
                                                <h2>{title}</h2>
                                                {paragraphs.map((text) => {
                                                    return (<p> {text} </p>);
                                                })}
                                            </div>

                                        </Grid>
                                        <Grid item xs={6}>
                                            <div className={'about-section-image'}>
                                                <img src={imageLink}  alt=""/>
                                            </div>

                                        </Grid>
                                    </Grid>
                                </Card>
                            </div>
                    }
                    {
                        last ?
                            <></>
                            :
                            <div>
                                <div className={'separator'}/>
                            </div>
                    }
                </div>

                :
                <div>
                    <div className={'centered-items'}>
                        <Card elevation={12} alignItems={'center'} sx={{ padding: 10, width: '80vw'}}>
                            <Grid container sm={14} display={'row'} alignItems={'center'} justifyContent={'center'} spacing={2}>

                                <Grid item xs={14}>
                                    <div className={'styled-text'}>
                                        <h2>{title}</h2>
                                        {paragraphs.map((text) => {
                                            return (<p> {text} </p>);
                                        })}
                                    </div>

                                </Grid>


                            </Grid>

                        </Card>
                    </div>
                </div>

    );
}

export default AboutSection;
