import '../../styles.css'
import {useRef, useState} from "react";
import {Card, FormControl, Grid, Paper, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import { styled } from '@mui/material/styles';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import IconButton from "@mui/material/IconButton";
import PhoneIcon from '@mui/icons-material/Phone';
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser'
import Box from "@mui/material/Box";

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#A0AAB4',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#B2BAC2',

    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#E0E3E7',
            backgroundColor: 'whitesmoke'

        },
        '&:hover fieldset': {
            borderColor: '#B2BAC2',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#6F7E8C',
        },
    },
});

const CustomButton = styled(Button)(() => ({
    color: 'white',
    borderColor: '#e41e25',
    backgroundColor:  'white',
    '&:hover': {
        backgroundColor:  '#f8baba',
        borderColor: '#e41e25',

    },
}));


function ContactForm(){

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')

    const form = useRef();

    const handleSubmit = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_l38taod', 'template_nthpzag', form.current, 'GQGlc9HEK3erskEee')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

        setName('')
        setEmail('')
        setPhone('')
        setMessage('')
    }


    return(
        <div className={'contact-form-container'}>

        <Card elevation={12} alignItems={'center'} sx={{ padding: 10}}>

            <h1>Intră în legătura cu noi</h1>
        <form ref={form} onSubmit={handleSubmit}>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" sm = {12}>


                <Grid item sm={12}>

                    <CssTextField
                        fullWidth
                        required
                        id="name"
                        label="Nume"
                        name="name"
                        margin="normal"
                        onChange={(event)=> {setName(event.target.value)}}
                        value={name}
                        variant={'filled'}
                    />
                </Grid>
                <Grid item sm={12}>
                    <CssTextField
                        fullWidth
                        required
                        id="email"
                        label="Email"
                        name="email"
                        margin="normal"
                        variant={'filled'}

                        onChange={(event)=> {setEmail(event.target.value)}}
                        value={email}

                    />

                </Grid>
                <Grid item sm={12}>
                    <CssTextField
                        fullWidth
                        required
                        id="phone"
                        label="Telefon"
                        name="phone"
                        margin="normal"
                        variant={'filled'}

                        onChange={(event)=> {setPhone(event.target.value)}}
                        value={phone}

                    />

                </Grid>
                <Grid item sm={12}>
                    <CssTextField
                        fullWidth
                        required
                        id="message"
                        label="Mesaj"
                        name="message"
                        margin="normal"
                        variant={'filled'}

                        multiline
                        onChange={(event)=> {setMessage(event.target.value)}}
                        value={message}

                    />

                </Grid>
                <Box width="100%"/>

                <Grid item sm={3} style={{marginTop: 10}} >

                    <CustomButton variant={'outlined'}
                                  type='submit'
                                  onClick={handleSubmit}>
                        Trimite
                    </CustomButton>


                </Grid>

                <Grid item sm={1} style={{marginTop: 10}}>
                        <IconButton style={{color: 'green'}}
                            onClick={()=>{
                                window.open('https://wa.me/40769077600')
                            }}
                        >
                            <WhatsAppIcon/>
                        </IconButton>
                </Grid>
                <Grid item sm={1} style={{marginTop: 10}}>

                        <IconButton  style={{color: 'green'}}
                             onClick={()=>{
                                 window.open('tel:+40769077600')
                             }}
                        >
                            <PhoneIcon/>
                        </IconButton>
                </Grid>

                <Grid item sm={2} style={{marginTop: 10}}>
                   <p style={{color: 'red'}}>
                        0769 077 600
                   </p>
                </Grid>

            </Grid>



        </form>


        </Card>
        </div>

    );
}

export default ContactForm;