import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

const spanStyle = {
    padding: '20px',
    background: '#efefef',
    color: '#000000',
    opacity: '0.2',
}

const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '60vh',
    width: 'auto'
}
const slideImages = [
    {
        url: 'https://lh3.googleusercontent.com/p/AF1QipNYEP2uEedEXI3fsdGANRnsjbPAP04erig7mTjd=s680-w680-h510'
    },
    {
        url: 'https://lh3.googleusercontent.com/p/AF1QipOB-mjCJBZbU7q3_q72lBHDUe4RL0AIB8HtWQkj=s680-w680-h510'
    },
    {
        url: 'https://lh3.googleusercontent.com/p/AF1QipMdVumlyDf3UJSWGtuduugHklaIWdYlFLNfGfqk=s680-w680-h510'
    },
    {
        url: 'https://lh3.googleusercontent.com/p/AF1QipPrtENQ8tC05fywFKaykheiwAJ2F5poY4HL8qMj=s680-w680-h510'
    },
    {
        url: 'https://lh3.googleusercontent.com/p/AF1QipOUSLvzN_r958D-1OHU7a7bcyXATSYobV0vsHBn=s680-w680-h510'
    },

];

function Slideshow () {
    return (
        <div className="slide-container">
            <Slide>
                {slideImages.map((slideImage, index)=> (
                    <div key={index}>
                        <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}>
                            {/*<span style={spanStyle}>{slideImage.caption}</span>*/}
                        </div>
                    </div>
                ))}
            </Slide>
        </div>
    )
}

export default Slideshow