import {Grid} from "@mui/material";

function Advantage({advantages}){

    return (
        <Grid container spacing={10} width={'100%'} justifyContent={'space-around'} sm={12}>
            {advantages.map((advantage) => {
               return <Grid item sm={4}>
                    <h2>
                        {advantage.title}
                    </h2>
                    <p>
                        {advantage.text}
                    </p>

                </Grid>
            })}
        </Grid>
    );
}

export default Advantage