import '../../styles.css'
import heroimage from '../../images/mobileexperts.png'
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router-dom';
function HomeHero(){

    const navigate = useNavigate();



    return (
        <div>
            <div className={'hero'}>
                <div className={'content'}>
                    <h1> Service auto Mobile Experts </h1>
                    <h2> Suntem un service auto profesional format dintr-o echipă tânără, dinamică și profesionistă, cu experiență în identificarea și rezolvarea problemelor grele în funcționarea autovehiculelor.</h2>
                    <Button variant={'outlined'} onClick={()=>{
                        navigate('/despre')
                    }}>AFLĂ MAI MULTE</Button>
                </div>
            </div>
        </div>

    );
}

export default HomeHero