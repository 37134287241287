import '../styles.css'
import HomeHero from "./home/HomeHero";
import Footer from "./Footer";
import Slideshow from "./about/Slideshow";
import AboutSection from "./about/AboutSection";
import mechanic1 from "../images/mechanic1.webp";
import Prices from "./Prices";
import {Fade, Slide} from 'react-reveal'

function Home() {
    return(

        <>

            <Fade duration={2000} top distance="50%">
            <HomeHero/>
            </Fade>

            <Fade duration={1500} left distance="0%">
            <div className={'separator'}/>
            </Fade>

            <Slide duration={1000} right distance="0%">
            <Prices/>
            </Slide>

            <Fade duration={1500} left distance="0%">
            <div className={'separator'}/>
            </Fade>

            <Fade duration={1500} left distance="0%">
            <div className={'about-section'}>
            <AboutSection imageLink={mechanic1}
                          title={'Service auto profesional'}
                          paragraphs={['   Bine ați venit la service-ul nostru auto cu peste 15 ani de experiență pe piață.\n' +
                          '                    Ne mândrim cu servicii de înaltă calitate, oferind reparații și întreținere pentru\n' +
                          '                    o gamă variată de mașini.',

                              '  Echipa noastră de mecanici bine pregătiți se specializează în reparații de motoare,\n' +
                              '                    curățare de filtre de particule și întreținere generală. Avem experiență în tratarea\n' +
                              '                    problemelor complexe ale motoarelor, inclusiv repararea injectoarelor și efectuarea\n' +
                              '                    reparațiilor mecanice de orice fel.'
                              ,

                              '  La service-ul nostru, punem accent pe calitatea lucrărilor noastre și pe satisfacția\n' +
                              '                    clienților noștri. Utilizăm tehnici și echipamente moderne pentru a ne asigura că\n' +
                              '                    fiecare mașină pleacă din service în condiții excelente de funcționare.']}
                          mirror={false}
                          last={true}
            />

            </div>
            </Fade>

            <Fade duration={1500} left distance="0%">
            <div className={'separator'}/>
            </Fade>

            <Fade duration={1500} left distance="0%">
            <Slideshow/>
            </Fade>

            <Fade duration={1500} left distance="0%">
            <Footer/>
            </Fade>

        </>

    )
}

export default Home