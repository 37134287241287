import {Grid} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

function Location(){
    const desktop = useMediaQuery('(min-width:768px)');

    return (
        desktop
        ?

        <Grid container width={'90'} sm={20} display={'row'} spacing={4} alignItems={'flex-start'} >

            <Grid item sm={6} >
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2819.426138389964!2d23.24837007625082!3d45.036573671070144!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x474d8b9a04bc3641%3A0xccb7cd586f24bcd6!2sMobile%20Experts!5e0!3m2!1sen!2sro!4v1692631417237!5m2!1sen!2sro"
                    allowFullScreen="" loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade">

                </iframe>

            </Grid>

            <Grid item sm={3}>
                    <h2>Program de lucru</h2>
                    <p>
                        L-V: 09:00 - 18:00
                    </p>
                    <p>
                        Sâmbătă: 09:00 - 13:00
                    </p>
                    <p>
                        Duminică & sărbători religioase/ legale: închis
                    </p>
            </Grid>

            <Grid item sm={3}>
                    <h2>Punct de lucru</h2>
                    <p>
                        jud. Gorj
                    </p>
                    <p>
                        Târgu Jiu
                    </p>
                    <p>
                        Calea Tismanei 26
                    </p>
            </Grid>

        </Grid>

            :

            <div>
                <div className={'location-info'}>
                    <h2>Program de lucru</h2>
                    <p>
                        L-V: 09:00 - 18:00
                    </p>
                    <p>
                        Sâmbătă: 09:00 - 13:00
                    </p>
                    <p>
                        Duminică & sărbători religioase/ legale: închis
                    </p>
                </div>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2819.426138389964!2d23.24837007625082!3d45.036573671070144!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x474d8b9a04bc3641%3A0xccb7cd586f24bcd6!2sMobile%20Experts!5e0!3m2!1sen!2sro!4v1692631417237!5m2!1sen!2sro"
                    allowFullScreen="" loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade">

                </iframe>


                <div className={'location-info'}>
                    <h2>Punct de lucru</h2>
                    <p>
                        jud. Gorj
                    </p>
                    <p>
                        Târgu Jiu
                    </p>
                    <p>
                        Calea Tismanei 26
                    </p>
                </div>

            </div>
    )
}

export default Location;