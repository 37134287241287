import React from 'react';
import {Card} from "@mui/material";


function Prices(props) {
    /*
    Reparat injectoare: de la 450 lei/buc, TVA inclus, in functie de piesele care necesita schimbate, in functie de tipul injectorului, de generatia acestuia, etc.
    Verificare injector: 50 lei/buc TVA inclus
    Curatare DPF 500 lei/buc, TVA inclus, OFERTA: Transport gratuit.
    */
    return (
        <div className={'centered-items'}>
            <Card elevation={12} alignItems={'center'} sx={{ padding: 10, width: '80vw'}}>

                <div className={'styled-text'}>
                    <h2>Listă de prețuri</h2>
                    <p>Reparat injectoare: de la 450 lei/buc, TVA inclus. Prețul se modifică în funcție de piesele care necesită schimbate, de tipul injectorului, de generația acestuia. </p>
                    <p>Verificare injector: 50 lei/buc TVA inclus. </p>
                    <p>Curățare DPF 500 lei/buc, pentru orice autoturism, TVA inclus. </p>
                    <p style={{color: 'red'}}>OFERTĂ: Transport gratuit pentru filtrele de particule.</p>
                </div>

            </Card>
        </div>
            );
}

export default Prices;