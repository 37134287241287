import './App.css';
import { createBrowserRouter, RouterProvider, Navigate} from "react-router-dom";
import RootPage from "./components/RootPage";
import Home from './components/Home'
import './styles.css'
import About from './components/about/About'
import DPF from './components/DPF-page/DPF'
import ContactForm from "./components/contact/ContactForm";
import Contact from "./components/contact/Contact";
import Injectors from "./components/injectors/Injectors";

const router = createBrowserRouter([
    {
        element: <RootPage />,
        children: [

            {
                path: "acasa",
                element:   <Home/>
            },

            {
                path: "despre",
                element:  <About/>
            },
            {
                path: "filtre-de-particule",
                element: <DPF/>
            },

            {
                path: "injectoare",
                element:  <Injectors/>
            },
            {
                path: "contact",
                element:  <Contact/>
            },


            {
                path: "*",
                element:  <Navigate to="/acasa" replace />
            },



        ],
    },
]);

function App() {
  return (
      <div>
          <RouterProvider router = {router}/>
      </div>
  );
}

export default App;
