import ContactForm from "./ContactForm";
import {Grid} from '@mui/material'
import Location from "./Location";
import Footer from "../Footer";
import {Fade, Slide} from 'react-reveal'


function Contact() {
    return (
        <>
        <div className={'contact-page'}>
            <Slide duration={1000} left>
            <ContactForm/>
            </Slide>

            <Slide duration={1000} right>
            <Location/>
            </Slide>

        </div>

            <Fade duration={1500} top distance="0%">
            <Footer/>
            </Fade>

        </>
            );
}

export default Contact