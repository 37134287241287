import React from 'react';
import '../../styles.css'
import AboutSection from "./AboutSection";
import Location from "../contact/Location";
import Footer from '../Footer'
import Slideshow from "./Slideshow";
import mechanic1 from '../../images/mechanic1.webp'
import mechanic2 from '../../images/mechanic2.webp'
import ContactForm from "../contact/ContactForm";
import {Card} from "@mui/material"
import {Fade, Slide} from 'react-reveal'

function About() {
    return (
        <div className="about-section">

            <Fade duration={2000} top distance="50%">

            <AboutSection imageLink={mechanic1}
                          title={'Service auto profesional'}
                          paragraphs={['   Bine ați venit la service-ul nostru auto cu peste 15 ani de experiență pe piață.\n' +
                          '                    Ne mândrim cu servicii de înaltă calitate, oferind reparații și întreținere pentru\n' +
                          '                    o gamă variată de mașini.',

                          '  Echipa noastră de mecanici bine pregătiți se specializează în reparații de motoare,\n' +
                          '                    curățare de filtre de particule și întreținere generală. Avem experiență în tratarea\n' +
                          '                    problemelor complexe ale motoarelor, inclusiv repararea injectoarelor și efectuarea\n' +
                          '                    reparațiilor mecanice de orice fel.'
                          ,

                          '  La service-ul nostru, punem accent pe calitatea lucrărilor noastre și pe satisfacția\n' +
                          '                    clienților noștri. Utilizăm tehnici și echipamente moderne pentru a ne asigura că\n' +
                          '                    fiecare mașină pleacă din service în condiții excelente de funcționare.']}
                mirror={false}
                />
            </Fade>

            <Fade duration={1500} left distance="0%">

            <Slideshow/>
            </Fade>

            <Fade duration={1500} left distance="0%">

            <div className={'separator'}/>
            </Fade>

            <Fade duration={2000} bottom distance="0%">

            <AboutSection imageLink={mechanic2}
                          title={'De ce să alegi Mobile Experts?'}
                          paragraphs={[
                              "Avem o experiență bogată în rezolvarea diverselor probleme auto, ceea ce ne permite să oferim soluții eficiente și rapide.",
                              "Ne angajăm să oferim prețuri transparente și competitive pentru serviciile noastre, asigurându-ne că clienții noștri primesc valoare pentru fiecare ban cheltuit.",
                              "Suntem conștienți că timpul clienților noștri este prețios. De aceea, ne străduim să rezolvăm problemele auto într-un timp cât mai scurt, fără a compromite calitatea muncii noastre.",
                              "Mii de clienți mulțumiți au apelat la serviciile noastre de-a lungul timpului, ceea ce atestă calitatea și profesionalismul cu care operăm.",
                              "Oferim clienților noștri sfaturi și consultanță specializată pentru întreținerea și repararea vehiculelor lor, contribuind astfel la prelungirea duratei de viață a acestora.",
                              "Înțelegem că fiecare vehicul și fiecare problemă sunt unice. Prin urmare, abordăm fiecare proiect cu o abordare personalizată și găsim soluții adaptate nevoilor fiecărui client."
                          ]}
                          mirror={true}
            />
            </Fade>

            <Slide duration={2000} left distance="50%">
            <div className={'contact-page'}>
                <Location/>
            </div>
            </Slide>

            <Fade duration={1500} left distance="0%">
            <div className={'separator'}/>
            </Fade>

            <Slide duration={1500} right distance="0%">
            <div className={'centered-items'}>
                <Card elevation={12} alignItems={'center'} sx={{ padding: 10, width: '80vw'}}>

                    <div className={'styled-text'}>
                        <h1>Suntem prea departe pentru tine?</h1>
                    </div>

                    <div className={'styled-text'}>
                    <h2>Distanța nu este o problemă!</h2>
                        <p>                    Dedicăm atenție și grijă tuturor clienților noștri, indiferent de locație. Înțelegem că problema DPF-ului sau a injectoarelor poate să apară oriunde, și tocmai de aceea suntem aici să vă ajutăm, chiar și dacă nu sunteți în proximitatea service-ului nostru.
                        </p>
                        <p>                    Dacă sunteți în căutarea unei soluții pentru DPF-ul sau injectoarele dumneavoastră și sunteți în afara zonei noastre de acoperire, avem o opțiune pentru dumneavoastră: puteți să ne trimiteți piesele prin curier și vă garantăm că acestea vor primi aceeași atenție și expertiză ca și cum ar fi fost aduse fizic la service-ul nostru.
                        </p>
                        <p>                    Procesul este simplu: tot ce trebuie să faceți este să luați legătura cu noi prin mijloacele de comunicare indicate și să ne descrieți problema. Vom evalua problemele și vă vom oferi o estimare a costurilor și timpului necesar pentru reparație. După ce sunteți de acord cu estimarea, puteți să ne trimiteți piesele pentru reparație. Vom lucra cu profesionalism și rapiditate pentru a restabili funcționalitatea pieselor mașinii dumneavoastră.
                        </p>
                        <p>                    Vă asigurăm că sunteți pe mâini bune. Avem o echipă de tehnicieni calificați și experimentați, care sunt dedicați să ofere soluții de calitate. Atât după finalizarea reparației, cât și în timpul acesteia vă vom contacta pentru a vă informa despre starea pieselor și pentru a coordona trimiterea lor către dumneavoastră.
                        </p>
                    </div>
                </Card>


            </div>
            </Slide>

            <Slide duration={2000} left distance="50%">
            <ContactForm/>
            </Slide>

            <Fade duration={1500} left distance="0%">
            <Footer/>
            </Fade>

        </div>


);
}

export default About;
