import DPFimg from '../../images/DPF.png'
import '../../styles.css'
import {Grid} from "@mui/material";
import Container from "@mui/material/Container";
import Advantage from '../DPF-page/Advantage'
import Footer from "../Footer";
import ContactForm from "../contact/ContactForm";
import { motion, useTransform, useScroll } from 'framer-motion'
import {Fade, Slide} from 'react-reveal'

const advantages = [
    { title: 'Mergeți de îndată la service', text: 'Dacă aveți în bord aprins "martor motor" sau "martor DPF", mergeți de îndată la service.' },
    { title: 'Alegeți un service profesionist', text: 'Alegeti un service care are profesionisti, nu încercați să rezolvați "ieftin" problemele apărute.' },
    { title: 'Folosiți uleiuri cu norme corespunzătoare', text: 'La schimbul uleiului trebuie să țineți cont că acesta să corespundă specificațiilor tehnice date de producător, iar intervalul de service și durata de viață a uleiului să fie resetate.' },
    { title: 'Fumul nu este normal', text: 'Dacă mașina scoate fum alb, albastru sau negru, mergeți de îndată la service.' },
    { title: 'Regenerarea din tester nu este întotdeauna potrivită', text: 'Nu efectuați regenerări forțate dacă motorul are probleme tehnice.' },
    { title: 'Alegeți curățarea pe stand dacă este necesară', text: 'Curățarea DPF-ului pe stand este "cea mai sănătoasă" metodă.' },
    { title: 'Completați procedura de curățare', text: 'După curățarea DPF-ului pe stand și remontarea acestuia pe mașină, solicitați o regenerare forțată care să fie "efectuată cu succes".' },
    { title: 'Nu uita de AdBlue', text: 'Nu uitați să verificați periodic nivelul aditivului de tratare a gazelor de evacuare.' },
    { title: 'Un DPF înfundat poate fi mai grav decât crezi', text: 'Un DPF înfundat poate distruge: injectoarele, EGR-ul, clapeta de accelerație, turbosuflanta, senzorii de temperatură, senzorul de presiune diferențială, sondele lambda, chiar și motorul.' },
    { title: 'Nu anula DPF-ul', text: 'Nu anulați DPF-ul. Este o decizie total neinspirată, care nu afectează doar mediul înconjurător, ci și funcționarea corectă a mașinii' }
];

function DPF(){
    return (
        <div>
            <Fade duration={1500} top distance="0%" cascade>

            <div className={'DPF'}>
                <div className={'irregular-image'}>
                    <Grid container spacing={10} width={'100%'} justifyContent={'space-around'} sm={14}>
                        <Grid item sm={6}>
                            <img  src={DPFimg} alt={''}/>
                        </Grid>

                        <Grid item sm={6}>
                            <h2>De unde știu dacă filtrul de particule este înfundat?</h2>
                            <ul>
                                <li>În bord se aprinde lampa "martor DPF"</li>
                                <li>Mașina nu trage corespunzător, pierde puterea.</li>
                                <li>Se limitează turația motorului</li>
                                <li>Se aude "zgomot de injecție" la accelerare</li>
                                <li>Crește nivelul uleiului în baie</li>
                                <li>Crește consumul de combustibil</li>
                                <li>Regenerarea se efectuează foarte des</li>
                                <li>Scoate fum alb, cu miros înțepător</li>
                            </ul>

                        </Grid>
                    </Grid>
                </div>
            </div>
            </Fade>

            <Fade duration={1500} top distance="0%">
            <div className={'separator'}/>
            </Fade>

            <Slide duration={2000} left distance="50%">
            <div className={'DPF'}>
                <Grid container spacing={10} width={'100%'} justifyContent={'center'} alignItems={'center'} sm={14}>
                    <Grid item sm={6}>
                        <h2>

                            De ce s-a înfundat filtrul de particule?
                        </h2>

                        <ul>
                            <li>Mersul îndelungat în regim urban</li>
                            <li>Lampa "martor motor" aprinsă în bord.</li>
                            <li>Probleme tehnice la motor: injectoare, EGR, bujii, clapetă aer, debitmetru, turbosuflantă, senzori, sonde lambda, SCR, etc.</li>
                            <li>Lipsa aditivilor de tratare a gazelor de evacuare sau nefuncționarea sistemului.</li>
                            <li>Utilizarea unui ulei necorespunzător sau neresetarea intervalului de service și a calității uleiului motor.</li>
                            <li>Mașina are peste 250000 km.</li>
                        </ul>

                    </Grid>

                    <Grid item sm={6}>

                        <h2> Cu ce s-a înfundat filtrul de particule? </h2>

                        <p> Calamină, ca rezultat al arderii necorespunzătoare a combustibilului în motor. </p>
                        <ul>
                            <li> Probleme ale sistemului de injecție de motorină </li>
                            <li> Probleme cu compresia în cameră de ardere </li>
                            <li> Probleme cu bujiile sau releul de bujii </li>
                            <li> Probleme cu sistemul de supraalimentare sau cu turbosuflanta </li>
                        </ul>
                        <p>Cenușa, ca rezultat al arderii uleiului de motor:</p>
                        <ul>
                            <li> Utilizarea unui ulei necorespunzător, nerecomandat </li>
                            <li> Consum de ulei motor: turbosuflantă uzată, motor uzat, epurator înfundat, etc. </li>
                        </ul>
                    </Grid>

                    <Grid item sm={6}>

                        <h2>Cum se curăță filtrul de particule? </h2>

                        <p>Prin regenerare </p>
                        <ul>
                            <li>Pasivă: se face în timpul mersului în regim de autostradă (la o încărcare a DPF-ului sub 20%)</li>
                            <li>Activă: se face la cererea calculatorului mașinii prin creșterea temperaturii gazelor de evacuare la 600-800°C, prin utilizarea postinjectiei sau injectiei de motorină în evacuare folosind al 5-lea injector. (la o încărcare a DPF-ului între 20% - 40%)</li>
                            <li>Forțată: se face în service, fiind o procedură utilizată sub supraveghere. (la o încărcare a DPF-ului sub 60%)</li>
                        </ul>

                        <p>Prin curățare chimică sau termică pe stand sau pe mașină.</p>
                        <ul>
                            <li> Recomandăm curățarea chimică, pe stand, fiind utilizate substanțe chimice profesionale
                                și un jet de apă și aer la o temperatură și presiune corespunzătoare, monitorizate și
                                reglate în funcție de capacitatea DPF-ului și de gradul de încărcare al acestuia. </li>
                        </ul>
                    </Grid>

                    <Grid item sm={6}>
                        <iframe src="https://www.youtube.com/embed/eCSXTbvuSxU"
                                title="Carbon Clean Ltd - DPF Demonstration - DCS-16 -Bucharest Auto Show &amp; Accessories 2018"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen>

                        </iframe>
                    </Grid>

                </Grid>
            </div>
            </Slide>

            <Fade duration={1500} left distance="0%">
            <div className={'separator'}/>
            </Fade>

            <Slide duration={1000} right distance="0%">
            <div className={'DPF'}>
                <div className={'container-dpf'}>
                    <h1>Câteva sfaturi utile atunci când filtrul are probleme</h1>

                </div>


                <Advantage advantages={advantages}/>


            </div>
            </Slide>

            <Fade duration={1500} top distance="0%">
            <div className={'separator'}/>
            </Fade>

            <Slide duration={1500} left distance="0%">
            <ContactForm/>
            </Slide>

            <Fade duration={1500} top distance="0%">
            <Footer/>
            </Fade>
        </div>

    );
}

export default DPF;