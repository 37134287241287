import '../../styles.css';
import {Grid} from "@mui/material";
import injectorsImg from "../../images/injectors.jpeg";
import Advantage from '../DPF-page/Advantage';
import ContactForm from "../contact/ContactForm";
import Footer from "../Footer";
import {Fade, Slide} from 'react-reveal'

function Injectors(){


    const advantages = [
        { title: "Martorii aprinși nu sunt normali", text: "Dacă aveți în bord aprins “martor motor”, mergeți de îndată la service." },
        { title: "Alegeți un service autorizat", text: "Alegeți un service care are profesioniști, nu încercați să rezolvați “ieftin” problemele apărute." },
        { title: "Aveți grijă la schimbul de filtre", text: "La schimbul filtrului de combustibil trebuie să țineți cont că acesta să fie de calitate, iar aerisirea sistemului de injectie să se facă conform indicațiilor producătorului. (Exemplu: este foarte importantă aerisirea cu comandă de la tester)" },
        { title: "Fumul nu este normal", text: "Dacă mașina scoate fum alb, albastru sau negru, mergeți de îndată la service." },
        { title: "Injectoarele second hand pot fi la fel de problematice ca cele pe care le aveți deja", text: "Nu înlocuiți injectoarele cu unele second hand decât după ce aveți certitudinea că acestea sunt verificate și corespund din punct de vedere tehnic." },
        { title: "Recomandăm să reparați injectoarele", text: "Repararea (nu recondiționarea) injectorului este cea mai indicată soluție." },
        { title: "Codarea injectoarelor de către service-ul auto este necesară", text: "După înlocuirea injectoarelor, nu uitați să memorati codul acestora în ECU." },
        { title: "Alimentați din timp", text: "Nu circulați preponderent cu rezervorul la minim. Motorina se încălzește și își pierde proprietățile, afectând astfel sistemul de injectie." },
        { title: "Injectoarele defecte pot afecta și alte piese în timp", text: "Injectoarele defecte pot distruge: turbosuflanta, EGR-ul și DPF-ul (se pot infunda mult mai ușor), SCR-ul, chiulasa, garnitura de chiulasa, pistoanele (se pot topi), etc." },
        { title: "Reparați injectoarele la timp", text: "Nu neglijați problemele la injectoare! Este o decizie total neinspirată!" }
    ];


    return (
        <div>
            <Fade duration={1500} top distance="0%" cascade>
            <div className={'DPF'}>
                <div className={'irregular-image'}>
                    <Grid container spacing={10} width={'100%'} justifyContent={'space-around'} sm={14}>
                        <Grid item sm={6}>
                            <img  src={injectorsImg} alt={''}/>
                        </Grid>

                        <Grid item sm={6}>
                            <h2>De unde știu dacă injectoarele sunt defecte?</h2>
                            <ul>
                                <li>Motorul pornește greu</li>
                                <li>Mersul motorului neregulat în relanti</li>
                                <li>Fum alb în relanti</li>
                                <li>Fum alb sau negru în timpul accelerării</li>
                                <li>Teava de evacuare noxe este neagră, umedă, uleioasă</li>
                                <li>Test de emisii eșuat</li>
                                <li>Lipsa puterii la accelerare sau motorul nu se turează</li>
                                <li>Întreruperi în relanti sau în sarcină</li>
                                <li>Zgomote metalice deranjante la accelerare în sarcină</li>
                                <li>Creșterea consumului de combustibil</li>
                                <li>Martori aprinși în bord</li>
                                <li>Corecții / valori de compensare mari (la diagnoză / tester)</li>
                                <li>Nivel crescut în baia de ulei</li>
                            </ul>

                        </Grid>
                    </Grid>
                </div>
            </div>
            </Fade>

            <Fade duration={1500} top distance="0%">
            <div className={'separator'}>

            </div>
            </Fade>

            <Slide duration={1000} left distance="50%">
            <div className={'DPF'}>
                <Grid container spacing={10} width={'100%'} justifyContent={'center'} alignItems={'center'} sm={14}>
                    <Grid item sm={6}>
                        <h2>

                            De ce s-au defectat injectoarele?
                        </h2>

                        <ul>
                            <li>Motorină de calitate inferioară, benzină alimentată din greșeală, apă în motorină</li>
                            <li>Impurități în sistemul de alimentare, filtru de combustibil nu este schimbat la timp sau a fost de calitate inferioară</li>
                            <li>Circulația frecventă cu rezervorul la minim</li>
                            <li>Componentele sistemului de injecție supraîncălzite, temperate</li>
                            <li>Circulația frecventă în regim redus de viteză, cu turații inferioare</li>
                            <li>Alte componente motor defecte: EGR, clapetă de accelerație, DPF infundat, etc</li>
                            <li>Intervenții neprofesioniste la sistemele de injecție</li>
                            <li>Uzură normală, dacă mașina are peste 250000 km</li>
                        </ul>

                    </Grid>

                    <Grid item sm={6}>

                        <h2> Ce verificăm la un injector </h2>

                        <ul>
                            <li>Presiunea de deschidere a duzei injectorului</li>
                            <li>Etanșeitatea duzei injectorului</li>
                            <li>Etanșeitatea returului injectorului</li>
                            <li>Cantitatea de combustibil scoasă pe retur în diferite regimuri de verificare</li>
                            <li>Parametrii bobinei sau ai elementului piezo</li>
                            <li>Starea și aspectul duzei injectorului</li>
                        </ul>
                    </Grid>

                    <Grid item sm={6}>


                    </Grid>

                    <Grid item sm={6}>

                        <iframe width="1280" height="720" src="https://www.youtube.com/embed/qHykxgcHUl0"
                                title="carbon zapp ds2 - testare injector" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen></iframe>
                    </Grid>

                </Grid>
            </div>
            </Slide>

            <Fade duration={1500} left distance="0%">
            <div className={'separator'}/>
            </Fade>

            <Slide duration={1500} right distance="0%">
            <div className={'DPF'}>
                <div className={'container-dpf'}>
                    <h1>Câteva sfaturi utile atunci când sistemul de injecție are probleme:</h1>

                </div>


                <Advantage advantages={advantages}/>


            </div>
            </Slide>

            <Fade duration={1500} left distance="0%">
            <div className={'separator'}/>
            </Fade>

            <Slide duration={1500} left distance="0%">
            <ContactForm/>
            </Slide>

            <Fade duration={1500} top distance="0%">
            <Footer/>
            </Fade>
        </div>

    );
}

export default Injectors